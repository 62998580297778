import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, goOffline, goOnline, ref, onValue } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

const firebaseConfigProd = {
    apiKey: "AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U",
    authDomain: "marine-center-database.firebaseapp.com",
    databaseURL: "https://marine-center-database-default-rtdb.firebaseio.com",
    projectId: "marine-center-database",
    storageBucket: "marine-center-database.appspot.com",
    messagingSenderId: "66934899393",
    appId: "1:66934899393:web:a588b9d1a06371dbd40a40",
    measurementId: "G-X43R3WLPE7"
};

const firebaseConfigTest = {
    apiKey: "AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U",
    authDomain: "marine-center-database.firebaseapp.com",
    databaseURL: "https://marine-center-database-test-data.firebaseio.com",
    projectId: "marine-center-database",
    storageBucket: "marine-center-database.appspot.com",
    messagingSenderId: "66934899393",
    appId: "1:66934899393:web:a588b9d1a06371dbd40a40",
    measurementId: "G-X43R3WLPE7"
};

const firebaseConfig = window.location.hostname === "marinetech.app" ? firebaseConfigProd : firebaseConfigTest;

// Prevent multiple Firebase initializations
let app;
if (!getApps().length) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApp(); // Use existing instance
}

const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const messaging = getMessaging(app);

const connectedRef = ref(database, ".info/connected");

let firebaseConnectedListenerAdded = false;
if (!firebaseConnectedListenerAdded) {
    onValue(connectedRef, (snapshot) => {
        console.log(snapshot.val() ? "User is connected to Firebase Realtime Database" : "User is disconnected from Firebase (Realtime Database)");
    });
    firebaseConnectedListenerAdded = true;
}

// Ensure authentication listener is only attached once
let authListenerRegistered = false;
if (!authListenerRegistered) {
    authListenerRegistered = true;
    auth.onAuthStateChanged((user) => {
        if (user) {
            console.log(`User is authenticated: ${user.uid}`);
        } else {
            console.warn("User is signed out.");
        }
    });
}

// Debounce goOnline/goOffline events
let reconnectTimeout;
window.addEventListener("online", () => {
    clearTimeout(reconnectTimeout);
    reconnectTimeout = setTimeout(() => goOnline(database), 2000);
});

window.addEventListener("offline", () => {
    clearTimeout(reconnectTimeout);
    reconnectTimeout = setTimeout(() => goOffline(database), 2000);
});

export { app, auth, database, functions, messaging, storage };
