import { get } from 'http';
import { auth, database }  from './initFirebase';
import { ref as authRef, onAuthStateChanged, getIdToken } from "firebase/auth";
import { ref , get as firebaseGet, child, onValue} from "firebase/database";
import { getFunctions, httpsCallable } from 'firebase/functions';

let currentURL;

if (window.location.hostname === "marinetech.app") {
  
  currentURL = "https://marine-center-database-default-rtdb.firebaseio.com";
} else {

  currentURL = "https://marine-center-database-test-data.firebaseio.com";
}

document.addEventListener("DOMContentLoaded", getCurrentDatabaseVersion);

let indexedVersion;

async function getCurrentDatabaseVersion() {
  const dbName = 'openDatabase';
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const version = db.version;
      indexedVersion = version;
      db.close();
      resolve(version);
    };

    request.onerror = (event) => {
      console.error('Error checking database version:', event.target.errorCode);
      resolve(null); // In case of error, resolve with null
    };
  });
}

export function generateId(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  export function toastMessage(message, status, time) {
    var x = document.getElementById("snackbar");
  
    // Set background color if status is provided
    if (status) {
      x.style.backgroundColor = status.toString();
    }
  
    // Set default timeout duration if not provided
    var timeOut = time || 3000;
  
    // Convert time from milliseconds to seconds for CSS
    var timeInSeconds = timeOut / 1000;
  
    // Set the message
    x.innerHTML = message;
  
    // Set animation with dynamic timing
    x.style.animation = `fadein 0.5s, fadeout 0.5s ${timeInSeconds}s, flash 1s infinite`;
    x.style.animationFillMode = "forwards";
  
    // Show the snackbar
    x.className = "show";
  
    // Hide the snackbar after it is shown for 'timeOut' duration
    setTimeout(function () {
      x.className = x.className.replace("show", "");
      x.style.animation = ""; // Reset the animation
    }, timeOut + 500); // Add 500ms to account for the fadein duration
  }

  function checkAuthState() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(user);
        } else {
          reject(new Error('User is not authenticated'));
        }
      });
    });
  }
  

  let cachedIdToken = null;
  let tokenExpirationTime = 0;
  
  export async function fetchIdToken() {
      const user = await checkAuthState();
      if (!user) {
          throw new Error("User not authenticated");
      }
  
      // Check if cached token is still valid
      const currentTime = Date.now();
      if (cachedIdToken && currentTime < tokenExpirationTime) {
          return cachedIdToken;
      }
  
      try {
          const idTokenResult = await user.getIdTokenResult();
          cachedIdToken = idTokenResult.token;
          tokenExpirationTime = idTokenResult.expirationTime ? new Date(idTokenResult.expirationTime).getTime() - 60000 : currentTime + 300000; // Cache for ~5 minutes
  
          return cachedIdToken;
      } catch (error) {
          console.error("Error fetching ID token:", error);
          throw error;
      }
  }
  

  export async function fetchFirebaseLastUpdated(firebasePath) {
    try {
      const idToken = await fetchIdToken();
      const firebaseUrl = `${currentURL}/${firebasePath}/lastUpdated.json?auth=${idToken}`;
  
      // Fetch the lastUpdated value
      const response = await fetch(firebaseUrl);
      if (!response.ok) throw new Error(`Firebase fetch failed with status ${response.status}`);
  
      const timestamp = await response.json();
  
      // If lastUpdated doesn't exist, set it to the current timestamp
      if (timestamp === null) {
        const currentTimestamp = Date.now();
        const updateUrl = `${currentURL}/${firebasePath}/lastUpdated.json?auth=${idToken}`;
        const updateResponse = await fetch(updateUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(currentTimestamp),
        });
  
        if (!updateResponse.ok) {
          throw new Error(`Failed to update lastUpdated with status ${updateResponse.status}`);
        }
  
        console.log(`lastUpdated set to ${currentTimestamp} for ${firebasePath}`);
        return currentTimestamp;
      }
  
      return timestamp;
    } catch (error) {
      console.error(`Error fetching or updating Firebase last updated timestamp for ${firebasePath}:`, error);
      throw error;
    }
  }

  export async function fetchIndexedDBLastUpdated(db, metadataKeyName) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['metadata'], 'readonly');
      const store = transaction.objectStore('metadata');
      const request = store.get(metadataKeyName);
  
      request.onsuccess = () => {
        resolve(request.result ? request.result.timestamp : 0);
      };
  
      request.onerror = (event) => {
        console.error(`Error fetching IndexedDB last updated timestamp for ${metadataKeyName}:`, event.target.error);
        reject(event.target.error);
      };
    });
  }

  export async function updateTimestamps(dataStoreNameIDB, dataStoreNameFirebase, timestamp) {
    // Timestamp to use for both IndexedDB and Firebase updates
    let currentTimestamp = timestamp ? timestamp : new Date().getTime();
  
    try {
      // Update timestamp in IndexedDB
      const db = await openIndexedDB(); // Assuming this function abstracts the IndexedDB open request
      const tx = db.transaction(['metadata'], 'readwrite');
      const store = tx.objectStore('metadata');
      const key = `${dataStoreNameIDB}LastUpdated`;
      store.put({ key: key, timestamp: currentTimestamp });
  
      // Update timestamp in Firebase
      const firebasePath = `${dataStoreNameFirebase}/lastUpdated`;
      if(dataStoreNameFirebase !== 'skip'){
      await updateFirebaseTimestamp(firebasePath, currentTimestamp);
      }
    } catch (error) {
      console.error(`Error updating timestamps for ${dataStoreNameIDB}:`, error);
    }
  }

  export function openIndexedDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('openDatabase', indexedVersion);
      request.onerror = (event) => reject(event.target.error);
      request.onsuccess = (event) => resolve(event.target.result);
    });
  }
  
  export async function updateFirebaseTimestamp(path, timestamp) {
    try {
      const idToken = await fetchIdToken();
      const firebaseUrl = `${currentURL}/${path}.json?auth=${idToken}`;
  
      const response = await fetch(firebaseUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(timestamp),
      });
  
      if (!response.ok) throw new Error('Firebase update failed');
    } catch (error) {
      console.error('Error updating Firebase timestamp:', error);
      throw error; // It's generally a good practice to throw the error to be handled by the caller
    }
  }

  export async function getJobData(jobId) {

    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open('openDatabase', indexedVersion);
  
      openRequest.onerror = (event) => {
        reject(new Error(`Error opening database: ${event.target.errorCode}`));
      };
  
      openRequest.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(['jobData'], 'readonly');
        const objectStore = transaction.objectStore('jobData');
        const getRequest = objectStore.get(jobId);
  
        getRequest.onerror = (event) => {
          reject(new Error(`Error getting job data: ${event.target.errorCode}`));
        };
  
        getRequest.onsuccess = (event) => {
          if (getRequest.result) {
  
            resolve(getRequest.result);
          } else {
  
            resolve(null);
          }
        };
      };
    });
  }

  export async function opCodeCheck(jobId) {

    const jobData = await getJobData(jobId);
    console.log(jobData);
    if (!jobData) {
      console.error(`No job data found for ${jobId}`);
      return null;
    }
    const opCode = jobData.OpCode;
    const opCodeDataBase = ref(database, 'opcodes'); // Reference to /opcode
  
    try {
      // Fetch the data for the given opCode
      const snapshot = await firebaseGet(child(opCodeDataBase, opCode));
      if (snapshot.exists()) {
        return snapshot.val(); // Return the data if it exists
      } else {
        return null; // Return null if the opcode doesn't exist
      }
    } catch (error) {
      console.error("Error fetching opcode data:", error);
      return null; // Handle errors gracefully
    }
  }

export function toProperCase(str) {
    return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}

export async function getCustomerDMID(customerId) {
  return new Promise((resolve, reject) => {
    // Open the IndexedDB database
    const request = indexedDB.open('openDatabase', indexedVersion);

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject('Database error: ' + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['customerData'], 'readonly');
      const store = transaction.objectStore('customerData');

      // Get the customer data by customerId
      const customerRequest = store.get(customerId);

      customerRequest.onerror = (event) => {
        console.error('Error fetching customer data:', event.target.errorCode);
        reject('Error fetching customer data: ' + event.target.errorCode);
      };

      customerRequest.onsuccess = (event) => {
        const customerData = event.target.result;
        // Ensure customerData and Boats[boatId] exists
        if (customerData) {
          resolve(customerData.DM_Customer);
        } else {
          reject('Boat data not found for customerId: ' + customerId + ', boatId: ' + boatId);
        }
      };
    };

    request.onupgradeneeded = (event) => {
      // Placeholder for database upgrade logic, if necessary
      // This is only called if you're connecting to a higher version of the database
      // than what exists in the browser, allowing you to create object stores, etc.
    };
  });
}

export async function getWorkOrderInfoDM(workOrder, billDate, invoiceNumber) {

  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await firebaseGet(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();
  const DM_URL = 'https://api.dockmaster.com:4134/';

  const url = `${DM_URL}api/v2/Service/WorkOrders/Retrieve?Id=${workOrder}&Detail=true`;
  const options = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };
  const data = [];
  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if(!result || !result.Operations || result.Operations.length === 0) {
      return;
    }

    result.Operations.forEach(op => {
      const info = {
        "Charge": `$${Number(op.TotalCharges).toFixed(2).toLocaleString()}`,
        "Description": op.LongDesc,
        "Work_Order": workOrder,
        "Bill_Date": billDate,
        "Invoice": invoiceNumber
      };
      data.push(info);
    });

    return data;
  } catch (error) {
    console.log(error);
  };

}

export async function getCustomerBalance(customer) {

  const DOCKMASTER_API_KEY = await checkAndUpdateDMToken();
  const DM_URL = 'https://api.dockmaster.com:4134/';

  const url = `${DM_URL}api/v2/Customers/RetrieveCustomer/${customer}`;
  const options = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if(!result.Invoices){
      return null;
    }

    if(result.Invoices){
      return result;
    }
  } catch (error) {
    console.error('Error fetching customer balance:', error);
    return null;
  }
}

export async function checkAndUpdateDMToken() {
  const apiRef = child(ref(database), 'API');
  const snapshot = await firebaseGet(apiRef);
  const dmConfig = snapshot.val();

  const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000;
  const lastUpdated = dmConfig.lastUpdated || 0;

  if (Date.now() - lastUpdated > twoDaysInMillis) {
    const functions = getFunctions();
    const updateToken = httpsCallable(functions, 'getDMTokenAndUpdateManual');
    
    try {
      await updateToken();
    } catch (error) {
      console.error('Error updating token:', error);
      throw error;
    }

    const updatedSnapshot = await get(apiRef);
    return updatedSnapshot.val().DockmasterDMToken;
  }

  return dmConfig.DockmasterDMToken;
}

export async function getUserRole(uid) {
  if (!navigator.onLine) {
      console.log('User is offline. Attempting to retrieve cached data.');

      return new Promise((resolve, reject) => {
          const userRoleRef = ref(database, `users/${uid}/role`);
          onValue(userRoleRef, (snapshot) => {
              if (snapshot.exists()) {
                  console.log('Retrieved cached user role:', snapshot.val());
                  resolve(snapshot.val());
              } else {
                  console.warn('No cached user role found.');
                  resolve(null);
              }
          }, (error) => {
              console.error('Error fetching cached user role:', error);
              reject(null);
          }, { onlyOnce: true });
      });
  }

  try {
      const userRoleRef = ref(database, `users/${uid}/role`);
      const snapshot = await firebaseGet(userRoleRef);

      if (snapshot.exists()) {
          return snapshot.val();
      } else {
          return null;
      }
  } catch (error) {
      console.error('Error fetching user role:', error);
      return null;
  }
}

export function fetchOperationsForWorkOrder(workOrderNumber) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('openDatabase');

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');

      const getRequest = objectStore.getAll();

      getRequest.onsuccess = () => {
        const allJobs = getRequest.result;
        const filteredJobs = allJobs.filter(job => {
          return (
            job.Work_Order === workOrderNumber &&
            job.Category.toString() !== 'Declined'
          );
        });
        resolve(filteredJobs);
      };

      getRequest.onerror = (event) => {
        reject(new Error(`Error fetching operations from IndexedDB: ${event.target.error}`));
      };
    };

    request.onerror = (event) => {
      reject(new Error(`Error opening IndexedDB: ${event.target.error}`));
    };
  });
}

export async function loadBoatsFromDatabase() {
  console.log("Loading Boats from IndexedDB");

  return new Promise((resolve, reject) => {
      try {
          // Open the IndexedDB database
          const dbPromise = indexedDB.open('openDatabase', indexedVersion);

          dbPromise.onsuccess = (event) => {
              const db = event.target.result;

              // Start a transaction to read from the 'mapDataBoats' store
              const transaction = db.transaction('mapDataBoats', 'readonly');
              const store = transaction.objectStore('mapDataBoats');
              const request = store.getAll();

              request.onsuccess = () => {
                  const boatDataArray = request.result;
                  resolve(boatDataArray); // Resolve the promise with the boat data
              };

              request.onerror = (event) => {
                  console.error("Error loading boat data from IndexedDB:", event.target.error);
                  reject(event.target.error); // Reject the promise with the error
              };
          };

          dbPromise.onerror = (event) => {
              console.error("Error opening IndexedDB:", event.target.error);
              reject(event.target.error); // Reject the promise if the DB fails to open
          };
      } catch (error) {
          console.error("Error in loadBoatsFromDatabase:", error);
          reject(error); // Reject the promise in case of other errors
      }
  });
}